
import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import Button from "../components/button"

export const query = graphql`
query issueTemplateQuery($uid: String) {
  prismic {
    allIssues(uid: $uid) {
        edges {
          node {
            cover_image
            cover_imageSharp {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            issue_theme
            literal_release_date
            identifier
            release_date
            contributors
            pdf {
              ... on PRISMIC__FileLink {
                _linkType
                name
                url
                size
              }
            }
            letter_from_the_editor
          }
        }
      }
  }
}
`
export default (props) => {
  // Define the Post content returned from Prismic
  const doc = props.data.prismic.allIssues.edges[0];
  if (!doc) return null;
  return (
    <div className="body">
      <Layout>
        <SEO title={doc.node.issue_theme[0].text} />
        <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
          <div className="section">
            <div className="preview template">
              <a href={doc.node.pdf ? doc.node.pdf.url : '/404'} className="card template w-inline-block">
                <Img fluid={doc.node.cover_imageSharp.childImageSharp.fluid} alt="" className="card-image" />
                <div className="card-info">
                  <h4>{doc.node.identifier[0].text}</h4>
                  <div>{doc.node.release_date[0].text}</div>
                </div>
              </a>
              <div className="preview">
                <h1 className="heading">{doc.node.issue_theme[0] ? doc.node.issue_theme[0].text : "Unthemed"}<br /></h1>
                <h4>Contributors:<br /></h4>
                <p>{doc.node.contributors[0] ? doc.node.contributors[0].text : "No Contributors"}</p>
                <div className="button-parent page">
                  <Button name="Read Issue" to={doc.node.pdf ? doc.node.pdf.url : '/404'} />
                  <Link to="/issues/">
                    <div className="button w-inline-block">Back to Issues</div>
                  </Link>
                </div>
              </div>
            </div>
            <h4>Letter From the Editor:<br /><br /></h4>

            <div className="w-richtext">
              {RichText.render(doc.node.letter_from_the_editor)}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

